import { SanityImage } from '@types'

// Get the 'true' aspect ratio of a sanity image, factoring in any cropping
const getSanityCroppedImageAspect = (image: SanityImage) => {
  let aspectRatio = image.dimensions.aspectRatio

  if (image.crop) {
    const cropWidth = 1.0 - (image.crop.left + image.crop.right)
    const cropHeight = 1.0 - (image.crop.top + image.crop.bottom)

    return aspectRatio * (cropWidth / cropHeight)
  }

  return aspectRatio
}

export default getSanityCroppedImageAspect
