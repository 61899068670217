import { SanityImage } from '@types'
import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const SRCSET_WIDTHS = [400, 600, 1000, 1400, 1800]
const DEFAULT_WIDTH = 800

// Generate array of image URLs based off current source
// Returns 1. a low res preview URL, 2. a default URL, and 3. a full srcset URL
const generateImageUrls = (
  source: SanityImage
): [string | null, string | null, string, { x: string; y: string }] => {
  const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET
  const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID

  // Init sanity client
  const client = sanityClient({
    dataset,
    projectId,
    useCdn: true,
    useProjectHostname: false,
  })
  const builder = imageUrlBuilder(client)
  const urlFor = builder.image(source)

  // Get intrinsic image aspect ratio
  const aspectRatio = source?.dimensions?.aspectRatio

  // Generate low res preview URL
  // Low res images are rendered on the server and as such, we stick with JPGs
  const srcLowRes = urlFor
    .width(aspectRatio > 1 ? 320 : 240)
    .auto('format')
    .blur(50)
    .toString()

  // Generate default URL
  const srcDefault = urlFor
    .width(DEFAULT_WIDTH)
    .auto('format')
    .quality(80)
    .url()

  // Generate srcset URL
  const srcSrcset = SRCSET_WIDTHS.map(width => {
    return `${urlFor.width(width).auto('format').quality(80)} ${width}w`
  }).join(', ')

  const focusX =
    source?.crop && source?.hotspot
      ? `${
          (source?.hotspot.x / (1 - source.crop.left - source.crop.right)) * 100
        }%`
      : '50%'
  const focusY =
    source?.crop && source?.hotspot
      ? `${
          (source?.hotspot.y / (1 - source.crop.top - source.crop.bottom)) * 100
        }%`
      : '50%'

  return [srcLowRes, srcDefault, srcSrcset, { x: focusX, y: focusY }]
}

export default generateImageUrls
