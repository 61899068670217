import React from 'react'

import { CurrencyCode, MoneyV2 } from '../../types/codegen/shopify'

type Props = {
  compareAtPrice?: MoneyV2 | null
  price: MoneyV2
  quantity?: number
}

const formatIntlCurrency = (
  amount: number,
  currencyCode: CurrencyCode,
  locale: string = 'en'
) => {
  return new Intl.NumberFormat('en', {
    currency: currencyCode,
    style: 'currency',
  }).format(amount)
}

const FormattedMoney = (props: Props) => {
  const { compareAtPrice, price, quantity = 1 } = props

  if (!price) {
    return null
  }

  const currencyCode = price?.currencyCode

  const formattedPrice = formatIntlCurrency(
    price.amount * quantity,
    currencyCode,
    'en'
  )

  if (compareAtPrice) {
    const formattedCompareAtPrice = formatIntlCurrency(
      compareAtPrice.amount * quantity,
      currencyCode,
      'en'
    )

    return (
      <>
        <s>{formattedPrice}</s>
        <span style={{ marginLeft: '5px' }}>{formattedCompareAtPrice}</span>
      </>
    )
  }

  // HACK: Return value wrapped in a fragment as TypeScript currently doesn't like returning
  // plain strings from functional components.
  // Source: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544#issuecomment-434215660
  return <>{formattedPrice}</>
}

export default FormattedMoney
