import { SanityVideo } from '@types'
import React, { useEffect, useRef, useState } from 'react'

import { getSanityImage } from '../../utils/getSanityImage'

type Props = {
  item: SanityVideo
}

const Video = (props: Props) => {
  const { item } = props

  const refVideo = useRef<HTMLVideoElement>(null)
  // const [showControls, setShowControls] = useState(false)

  let posterImageUrl: string | undefined

  const POSTER_IMAGE_WIDTH = 1280
  const imageAspectRatio = item?.posterImage?.dimensions?.aspectRatio
  const imageHeight = Math.round(POSTER_IMAGE_WIDTH / imageAspectRatio)

  if (item?.posterImage) {
    posterImageUrl =
      getSanityImage(item.posterImage)
        .auto('format') // Try and use WebP, if supported
        .width(POSTER_IMAGE_WIDTH)
        .height(imageHeight)
        .toString() || ''
  }

  // Callbacks
  /*
  const handleStartPlay = () => {
    if (refVideo?.current) {
      refVideo.current.play()
    }
  }

  const handlePlay = () => {
    setShowControls(true)
  }
  */

  // Effects
  /*
  // Display controls once video starts playing
  useEffect(() => {
    refVideo.current?.addEventListener('play', handlePlay)

    return () => {
      refVideo.current?.removeEventListener('play', handlePlay)
    }
  }, [])
  */

  useEffect(() => {
    const player = new window.Plyr(refVideo.current, {
      controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'settings',
        'fullscreen',
      ],
      quality: {
        default: 1080,
        options: [1080, 720, 576],
      },
      ratio: '16:9', // force aspect ratio
      settings: ['quality'],
    })
    player.source = {
      poster: posterImageUrl,
      sources: [
        {
          src: item?.video?.hd?.url,
          type: 'video/mp4',
          size: 720,
        },
        {
          src: item?.video?.fhd?.url,
          type: 'video/mp4',
          size: 1080,
        },
        {
          src: item?.video?.sd?.url,
          type: 'video/mp4',
          size: 576,
        },
      ],
      type: 'video',
    }
  }, [])

  return (
    <video
      className="plyr"
      controls
      // controls={showControls}
      // onClick={showControls ? undefined : handleStartPlay}
      data-poster={posterImageUrl}
      preload="none"
      ref={refVideo}
      style={{
        // cursor: showControls ? 'default' : 'pointer',
        display: 'block',
        outline: 'none',
        width: '100%',
      }}
    />
  )
}

export default Video
