import styled from 'styled-components'

import TextBody from '../TextBody'

const Button = styled(TextBody)`
  background: none;
  border: 0;
  border-bottom: 1px solid transparent;
  color: inherit;
  cursor: pointer;
  outline: none;
  padding: 0;
  text-transform: inherit;
  transition: opacity 150ms ease-out;
  user-select: none;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  @media (hover: hover) and (pointer: fine) {
    :not(:disabled) {
      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }
`
Button.defaultProps = {
  as: 'button',
}

export default Button
