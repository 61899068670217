import { SanityPageHomeProps, SanityPageHomeResult } from '@types'
import { GetStaticProps } from 'next'
import React from 'react'

import Box from '../components/Box'
import BoxMotion from '../components/BoxMotion'
// import ButtonCart from '../components/ButtonCart'
// import Cart from '../components/Cart'
import Flex from '../components/Flex'
import ImageResponsive from '../components/ImageResponsive'
import PortableText from '../components/PortableText'
import PortableTextLarge from '../components/PortableTextLarge'
import PortableTextSmall from '../components/PortableTextSmall'
import ProductCard from '../components/ProductCard'
import SEO from '../components/SEO'
import { pageHome } from '../lib/sanityFragments'
import getActiveProducts from '../services/getActiveProducts'

const HomePage = (props: SanityPageHomeProps) => {
  const { data } = props

  return (
    <>
      {/* Page content */}
      <BoxMotion
        animate={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        transition={{
          delay: 1.5,
          duration: 1,
        }}
      >
        {/* Cart */}
        {/* <Cart /> */}

        {/* Cart button */}
        {/*
        <Box p={2} position="fixed" right={0} top={0} zIndex="header">
          <ButtonCart />
        </Box>
        */}

        {/* Feature text */}
        {data?.featureText && (
          <Box flex={1} mb={['20px', null, 5]} paddingRight={2}>
            <PortableText blocks={data.featureText} />
          </Box>
        )}

        {/* Image */}
        {data?.headerImage && (
          <Box
            maxWidth="1000px"
            mb="40px"
            // mt={['40px', null, 0]}
          >
            <ImageResponsive image={data?.headerImage} />
          </Box>
        )}

        <Flex flexDirection={['column', 'column', 'row']} maxWidth="1000px">
          {/* Intro text */}
          {data?.introText && (
            <Box flex={1} mb={['20px', null, 0]} paddingRight={2}>
              <PortableText
                blocks={data.introText}
                maxWidth={['163px', null, '210px']}
              />
            </Box>
          )}

          {/* Products */}
          {data?.products?.map((product, index) => (
            <Box flex={1} key={index} mb={['20px', null, 0]} paddingRight={2}>
              <Box maxWidth="198px">
                <ProductCard product={product} />
              </Box>
            </Box>
          ))}

          {/* Additional text */}
          {data?.additionalText && (
            <Box
              flex={1}
              mt={['20px', null, 0]}
              paddingLeft={[0, null, 2]}
              textAlign={['left', 'left', 'right']}
            >
              <PortableTextSmall blocks={data.additionalText} />
            </Box>
          )}
        </Flex>

        {/* Images */}
        <Box maxWidth="1000px" mt={[2, null, 4]}>
          {data?.images?.map((image, index) => (
            <ImageResponsive image={image} key={index} mb={[3, null, 4]} />
          ))}
        </Box>

        {/* Video */}
        <Flex
          flexDirection={['column', null, 'row']}
          maxWidth="1000px"
          my={[2, null, 3]}
        >
          {data?.video && (
            <Box flex={1} paddingRight={[0, null, 3]}>
              <video
                autoPlay
                loop
                muted
                playsInline
                src={data?.video?.url}
                style={{
                  width: '100%',
                }}
              />
            </Box>
          )}

          {data?.videoCaption && (
            <Box flex={1} marginTop={[1, null, 0]} paddingRight={[0, null, 3]}>
              <PortableTextSmall blocks={data.videoCaption} />
            </Box>
          )}
        </Flex>

        {/* Footer */}
        <Flex
          alignItems={['flex-start', null, 'flex-end']}
          flexDirection={['column', null, 'row']}
          maxWidth="1000px"
          // mt={[5, null, 6]}
        >
          {/* Contact */}
          {data?.contact && (
            <Box flex={1}>
              <PortableTextLarge blocks={data.contact} />
            </Box>
          )}

          {/* Colophon */}
          {data?.colophon && (
            <Box flex={1} mt={[2, null, 0]}>
              <PortableTextLarge blocks={data.colophon} />
            </Box>
          )}
        </Flex>
      </BoxMotion>

      {/* SEO */}
      <SEO />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const { sanityClientRead } = require('../lib/sanity')
  const sanityResult: SanityPageHomeResult = await sanityClientRead.fetch(
    pageHome()
  )

  // Strip empty objects from array
  const sanityProducts = sanityResult?.products?.filter(
    v => Object.keys(v).length !== 0
  )

  const activeProducts = await getActiveProducts(sanityProducts)

  return {
    props: {
      data: {
        ...sanityResult,
        products: activeProducts,
      },
    },
    revalidate: 60, // seconds
  }
}

export default HomePage
