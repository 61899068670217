import { BoxProps, SanityImage } from '@types'
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'

import useImageSrcsetLoaded from '../../hooks/useImageSrcsetLoaded'
import useOnScreen from '../../hooks/useOnScreen'
import theme from '../../styled/theme'
import generateImageUrls from '../../utils/generateImageUrls'
import Box from '../Box'
// import Debug from '../Debug'

type Props = BoxProps & {
  objectFit?: 'contain' | 'cover'
  objectPosition?: string | string[]
  onImageLoaded?: () => void
  source: SanityImage
  srcSetSizes?: string | string[]
}

// Show debug info
// const DEBUG = false

// Generate srcset sizes based off theme breakpoints
const generateSrcSetSizes = (sizes: string | string[]) => {
  if (typeof sizes === 'string') {
    return sizes
  }

  if (sizes.length === 1) {
    return sizes[0]
  }

  return sizes
    .map((size, i) => {
      if (i === sizes.length - 1) {
        return sizes[i]
      }
      return `(max-width: ${theme.breakpoints[i]}) ${size}`
    })
    .join(', ')
}

const ImageBase = (props: Props) => {
  const {
    source: image,
    objectFit = 'cover',
    objectPosition,
    onImageLoaded,
    srcSetSizes = '100vw',
    ...boxProps
  } = props

  // Refs
  const refContainer = useRef(null)
  const refImageSrcset = useRef(null)

  const isTransparent = image?.mimeType === 'image/png'

  // State
  const [previewVisible, setPreviewVisible] = useState(!isTransparent)

  // Check if container is in viewport
  const { inViewport } = useOnScreen(refContainer, {
    intersectionObserver: {
      rootMargin: '40px',
    },
    once: true,
  })

  // Check if large img srcset has loaded
  const imageSrcsetLoaded = useImageSrcsetLoaded(
    refImageSrcset,
    [inViewport, refImageSrcset],
    onImageLoaded
  )

  const handleImageAnimationComplete = () => {
    if (imageSrcsetLoaded && previewVisible) {
      setPreviewVisible(false)
    }
  }

  const [srcLowRes, srcDefault, srcSrcset, focus] = generateImageUrls(image)

  // Use `objectPosition` prop (if provided), sanity image focus, or default to center
  const imageObjectPosition =
    objectPosition || (focus ? `${focus.x} ${focus.y}` : '50% 50%')

  return (
    <>
      <Box
        position="absolute"
        ref={refContainer}
        size="100%"
        userSelect="none"
        zIndex={0}
      >
        {/* Srcset image */}
        {inViewport && (
          <motion.div
            // framer-motion
            animate={{
              opacity: imageSrcsetLoaded ? 1 : 0,
            }}
            initial={{
              opacity: 0,
            }}
            onAnimationComplete={handleImageAnimationComplete}
            transition={{
              delay: previewVisible ? 0.25 : 0,
              duration: 0.75,
              ease: 'easeOut',
              type: 'tween',
            }}
            // props
            style={{
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 2,
            }}
          >
            <Box
              as="img"
              draggable="false"
              left={0}
              objectFit={objectFit}
              objectPosition={imageObjectPosition}
              opacity={imageSrcsetLoaded ? 1 : 0}
              pointerEvents="none" // Disable 3D / force touch on iOS
              position="absolute"
              ref={refImageSrcset}
              src={srcDefault}
              srcSet={srcSrcset}
              size="100%"
              sizes={generateSrcSetSizes(srcSetSizes)}
              top={0}
              userDrag="none"
              {...boxProps}
            />
          </motion.div>
        )}

        {/* Preview color */}
        {previewVisible && (
          <Box
            bg="#eee"
            left={0}
            position="absolute"
            size="100%"
            top={0}
            userDrag="none"
            zIndex={1}
            {...boxProps}
          />
        )}

        {/* Preview image */}
        {/*previewVisible && (
          <Box
            as="img"
            draggable="false"
            left={0}
            objectFit={objectFit}
            objectPosition={imageObjectPosition}
            pointerEvents="none" // Disable 3D / force touch on iOS
            position="absolute"
            src={srcLowRes}
            size="100%"
            sizes={generateSrcSetSizes(srcSetSizes)}
            top={0}
            userDrag="none"
            zIndex={1}
            {...boxProps}
          />
        )*/}
      </Box>

      {/* Debug info */}
      {/*DEBUG && (
        <Debug>
          inViewport: {inViewport.toString()}
          <br />
          preview visible? {previewVisible.toString()}
          <br />
          srcSet: {srcSetSizes}
          <br />
          imageSrcsetLoaded: {imageSrcsetLoaded.toString()}
        </Debug>
      )*/}
    </>
  )
}

export default ImageBase
