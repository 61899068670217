import { BoxProps } from '@types'
import React from 'react'
import styled from 'styled-components'
import { system } from 'styled-system'

import Box from '../Box'

type Props = BoxProps & {
  aspectRatio: number | number[]
  children?: React.ReactNode
  onClick?: () => void
}

const Container = styled(Box)`
  ${system({
    aspectRatio: {
      property: 'paddingBottom',
      transform: val => `${100 / val}%`,
    },
  })}
`

const BoxResponsive = (props: Props) => {
  const { aspectRatio, children, onClick, ...boxProps } = props

  return (
    <Container
      aspectRatio={aspectRatio}
      height={0}
      onClick={onClick}
      position="relative"
      width="100%"
      {...boxProps}
    >
      <Box position="absolute" size="100%">
        {children}
      </Box>
    </Container>
  )
}

export default BoxResponsive
