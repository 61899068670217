import { ProductCardData } from '@types'
import React from 'react'

import { ProductVariantFragment } from '../../types/codegen/shopify'
import Box from '../Box'
import ButtonAddToCart from '../ButtonAddToCart'
import Flex from '../Flex'
import PortableText from '../PortableText'
import PresentmentPriceRange from '../PresentmentPriceRange'
import TextBody from '../TextBody'

type Props = {
  product: ProductCardData
}

const ProductCard = (props: Props) => {
  const { product } = props

  const sanity = product?.sanity
  const shopify = product?.shopify
  const priceRange = shopify?.presentmentPriceRanges?.edges

  const variants:
    | undefined
    | ProductVariantFragment[] = shopify?.variants?.edges?.map(
    edge => edge.node
  )

  const defaultVariant = variants ? variants[0] : undefined

  return (
    <Flex
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      maxWidth="350px"
    >
      {/* Body */}
      {sanity?.body && <PortableText blocks={sanity.body} maxWidth="230px" />}

      {/* Price range */}
      <TextBody marginTop={[2, null, '40px']}>
        {priceRange && (
          <Box>
            <Box display="inline" fontWeight="bold">
              <PresentmentPriceRange priceRange={priceRange} />
            </Box>{' '}
            + postage
          </Box>
        )}

        {/* Add to cart / sold out */}
        {shopify?.availableForSale && defaultVariant ? (
          <ButtonAddToCart
            preorder={sanity?.preorder}
            productVariant={defaultVariant}
          />
        ) : (
          <>Sold out</>
        )}
      </TextBody>
    </Flex>
  )
}

export default ProductCard
