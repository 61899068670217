import { SanityImage } from '@types'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { OpenGraphImages } from 'next-seo/lib/types'
import React from 'react'

import { getSanityImage } from '../../utils/getSanityImage'
import useTypedSelector from '../../hooks/useTypedSelector'

type Props = {
  description?: string
  image?: SanityImage
  title?: string
}

const SEO = (props: Props) => {
  const { description, image, title } = props

  const router = useRouter()

  // Get site meta from system reducer
  const meta = useTypedSelector(state => state.system.meta)

  const canonicalUrl =
    (process.env.NEXT_PUBLIC_HOSTNAME
      ? `https://${process.env.NEXT_PUBLIC_HOSTNAME}`
      : '') + router.asPath

  // Defaults
  const baseTitle = meta?.title
  const baseImage = meta?.image

  // Title and description
  const metaDescription = description || meta?.description
  const metaTitle = title ? `${title} | ${baseTitle}` : baseTitle
  const metaImages: OpenGraphImages[] = []

  // Image
  const IMAGE_WIDTH = 1200
  const metaImage = image || baseImage

  if (metaImage) {
    const imageAspectRatio = metaImage?.dimensions?.aspectRatio
    const imageHeight = Math.round(IMAGE_WIDTH / imageAspectRatio)

    metaImages.push({
      height: imageHeight,
      width: IMAGE_WIDTH,
      url:
        getSanityImage(metaImage)
          .auto('format') // Try and use WebP, if supported
          .width(IMAGE_WIDTH)
          .height(imageHeight)
          .toString() || '',
    })
  }

  return (
    <NextSeo
      canonical={canonicalUrl}
      description={metaDescription}
      openGraph={{
        description: metaDescription,
        images: metaImages,
        site_name: baseTitle,
        title: metaTitle,
        url: canonicalUrl,
      }}
      title={metaTitle}
      twitter={{
        cardType: 'summary_large_image',
      }}
    />
  )
}

export default SEO
