import { BoxProps, SanityBlock } from '@types'
import BlockContent from '@sanity/block-content-to-react'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Box from '../Box'
import TextBody from '../TextBody'

type Props = BoxProps & {
  blocks: SanityBlock[]
}

const BlockNormal = styled(TextBody)`
  margin: 0.8em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const renderPortableTextMarks = () => {
  return {
    annotationLinkEmail: (props: any) => {
      return (
        <a className="underline" href={`mailto:${props?.mark?.email}`}>
          {props.children}
        </a>
      )
    },
    annotationLinkExternal: (props: any) => {
      return (
        <a
          className="underline"
          href={props?.mark?.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {props.children}
        </a>
      )
    },
    strong: (props: any) => {
      return <strong>{props.children}</strong>
    },
  }
}

const PortableTextSmall = (props: Props) => {
  const { blocks, ...boxProps } = props

  const renderBlock = (props: { children: ReactNode; node: SanityBlock }) => {
    const { children, node } = props

    if (node.style === 'normal') {
      return (
        <BlockNormal as="p" fontSize={0}>
          {children}
        </BlockNormal>
      )
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
  }

  const serializers = {
    // Marks
    marks: renderPortableTextMarks(),
    // Block types
    types: {
      block: renderBlock,
    },
  }

  return (
    <Box {...boxProps}>
      <BlockContent
        blocks={blocks}
        className="block-content"
        renderContainerOnSingleChild
        serializers={serializers}
      />
    </Box>
  )
}

export default PortableTextSmall
