import { useCallback, useEffect, useState } from 'react'

// Force a delay before marking an item as complete, even if it's already been loaded
function useImageSrcsetLoaded(
  ref: React.RefObject<HTMLImageElement>,
  conditions: any[],
  callback?: () => void
) {
  const [isLoaded, setLoaded] = useState(false)

  const handleImageLoaded = useCallback(() => {
    setLoaded(true)

    if (callback) {
      callback()
    }
  }, conditions)

  useEffect(() => {
    const img = ref.current

    if (img) {
      // Mark as loaded if image has already been loaded
      if (img.complete) {
        setLoaded(true)
      }

      img.addEventListener('load', handleImageLoaded)
    }

    return () => {
      if (img) {
        img.removeEventListener('load', handleImageLoaded)
      }
    }
  }, conditions) // Use effect on mount, unmount and when `load` changes

  return isLoaded
}

export default useImageSrcsetLoaded
